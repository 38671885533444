import React from "react";
import Layout from "../components/Layout";
import { Link } from "gatsby";


const SuccessPage = class extends React.Component {
  componentDidMount() {
    let root = document.documentElement;
    root.style.setProperty('--tp-navbar-color', '#374836');
	}

  render() {
    return  (
    <Layout>
      <div className="container simple-page mt-6 mb-6">
        <h1>Bedankt!</h1>
        <p>Uw formulierinzending is succesvol ontvangen.</p>
        <Link to="/" className="nav-link" style={{textDecoration: 'underline'}} title="home">Terug naar de home pagina</Link>
      </div>
    </Layout>);
  }
}

export default SuccessPage;
